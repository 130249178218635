/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useRef, useEffect } from 'react'
import Helmet from 'react-helmet'
import axios from 'axios'
import { SEO } from '@renderbus/common/components'
import { baseLink } from '@renderbus/common/service'
import {
  RCSignFormContainer,
  PageContainer,
  RCStyle,
  RCFormSection,
  RCSignForm,
  RCSignFormTitle,
  RCFormItem,
  RCLabel,
  RCRegisterLabel,
  RCRenderbusSupport,
  BottomLine,
  RCInput,
  RCRadioContainer,
  RCRadio,
  RCCheckbox,
  RCAgreements,
  QrContainer,
  RCButton,
  RCDialogContainer,
  PageFooterMobile,
  RCInputField,
  RCOrgFormItem,
  RCSelectFormItem,
  ErrorWrapper,
  ErrorText,
  // RCCheckboxContainer,
  // RCFormCheckbox,
  WorkUploadInput,
} from './rc-form.atom'

import QRImg from '../images/render-contest/qr@2x.png'
import QRImgMb from '../images/render-contest/qrCodeMb.png'
import { generateRandomFileName, Plupload } from '@renderbus/common/utils'
import { formatFileSize } from '@renderbus/common/utils/format'
import PngIcon from '../images/render-contest/png_icon.png'
import PngWarningIcon from '../images/render-contest/png_warning_icon.png'
import CloseIcon from '../images/render-contest/close.png'
import CloseWarningIcon from '../images/render-contest/close_warning.png'

const formItemProps = [
  { id: '#category', type: 'radio' },
  { id: '#name', type: 'input' },
  { id: '#phone', type: 'input' },
  { id: '#email', type: 'input' },
  { id: '#wechat', type: 'input' },
  { id: '#account', type: 'input' },
  { id: '#school', type: 'input' },
  { id: '#major', type: 'input' },
  { id: '#company', type: 'input' },
  { id: '#position', type: 'input' },
  { id: '#info-from', type: 'radio' },
  { id: '#checkbox-agree-1', type: 'checkbox' },
  { id: '#checkbox-agree-2', type: 'checkbox' },
]
const infoSourceOptions = [
  'Renderbus相关平台',
  '朋友圈和社群',
  'B视抖红等视频平台',
  'CG世界相关平台',
]
const categoryOptions = ['专业组', '学生组']
const inputFields = new Map([
  ['category', '参赛组别'],
  ['name', '姓名'],
  ['email', '邮箱'],
  ['phone', '联系电话'],
  ['wechat', '微信号'],
  ['account', '瑞云账号'],
  ['school', '学校全称'],
  ['major', '专业名称'],
  ['company', '公司名称/组织结构'],
  ['position', '担任的职位'],
])
const initForm = {
  category: '',
  name: '',
  phone: '',
  email: '',
  wechat: '',
  account: '',
  school: '',
  major: '',
  company: '',
  position: '',
  infoFrom: '',
}
// 必填
function setRequiredStyle(id, type = 'input') {
  const element = document.querySelector(id)
  if (!element) return
  let isValid = false
  if (type === 'input') {
    isValid = !!element.value.trim()
  }
  if (type === 'checkbox') {
    isValid = element.checked
  }
  const validateClassName = element.className.replace(' error', '')
  const invalidClassName = validateClassName + ' error'
  element.className = !isValid ? invalidClassName : validateClassName
  return [isValid, validateClassName, invalidClassName]
}
// 对于错误需要展示错误信息
function setErrorBottomStyle(id, isValid) {
  const element = document.querySelector(id + '-error')
  if (!element) return
  const validateClassName = element.className.replace(' error', '')
  const invalidClassName = validateClassName + ' error'
  element.className = !isValid ? invalidClassName : validateClassName
}

function RenderContestSignUp() {
  const [formData, setFormData] = useState({ ...initForm })
  const [isDialogVisible, setIsDialogVisible] = useState(false)
  const [checkAgree, setCheckAgree] = useState(false)
  const [checkAgree2, setCheckAgree2] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [emailRight, setEmailRight] = useState(true)
  const [phoneRight, setPhoneRight] = useState(true)
  const [accountRight, setAccountRight] = useState(true)
  const [isOther, setIsOther] = useState(false)
  const [otherDetail, setOtherDetail] = useState('')
  const pageContainer = useRef(null)
  const [uploaderStudentCard, setUploaderStudentCard] = useState(null)
  const [uploadStudentCardResult, setUploadStudentCardResult] = useState(null)
  const [uploadingStudentCard, setUploadingStudentCard] = useState(false)
  const [studentCardName, setStudentCardName] = useState('')
  const [studentCardSize, setStudentCardSize] = useState('')
  const [isStudentCardTypeError, setIsStudentCardTypeError] = useState(false)
  const [studentCardFormError, setStudentCardFormError] = useState(false)

  useEffect(() => {
    const uploadSignatureURL = `https://www.renderbus.com/api/rc/04/oss/signature/?type=1`
    if (uploaderStudentCard) {
      uploaderStudentCard.setUploadSignatureURL(uploadSignatureURL)
      uploaderStudentCard.refresh()
      return
    }
    const _uploader = new Plupload({
      browseButtonId: 'browse-student-card-button',
      dragElementId: 'upload-student-card-container',
      uploadAfterAdd: true,
      uploadSignatureURL,
      filters: {
        mime_types: 'image/jpeg,image/png',
        max_file_size: '2mb',
      },
    })
    _uploader.setFilesAddedCallback((up, file) => {
      setUploadStudentCardResult(null)
      setStudentCardFormError(false)
      setUploadingStudentCard(true)
      file.name = generateRandomFileName()
      setStudentCardName(file.name)
      setStudentCardSize(formatFileSize(file.size))
    })
    _uploader.setFilesUploadedCallback((up, file, info) => {
      if ([200, 203].includes(info.status)) {
        setStudentCardFormError(false)
        setUploadStudentCardResult({
          success: true,
          fail: false,
        })
      } else {
        setUploadStudentCardResult({
          success: false,
          fail: true,
        })
      }
      setUploadingStudentCard(false)
    })
    _uploader.setFilesUploadErrorHandler((up, err) => {
      if (err) {
        if (err.code === -601) {
          setIsStudentCardTypeError(true)
        } else {
          setIsStudentCardTypeError(false)
        }
        setUploadStudentCardResult({
          success: false,
          fail: true,
        })
        setUploadingStudentCard(false)
      }
    })
    _uploader.setFilesUploadingHandler((up, file) => {
      const uploadBar = document.querySelector('.progress')
      uploadBar.style.width = `${file.percent}%`
    })
    _uploader && _uploader.init()
    setUploaderStudentCard(_uploader)
  }, [uploaderStudentCard, uploadStudentCardResult])

  function validateRequired(id, type, value) {
    if (!type) return
    if (type === 'input') {
      if (
        (formData.category === '专业组' && id === '#school') ||
        (formData.category === '专业组' && id === '#major') ||
        (formData.category === '学生组' && id === '#company') ||
        (formData.category === '学生组' && id === '#position')
      )
        return true
      if (!formData.category && ['#company', '#position'].includes(id)) return false
      const result = setRequiredStyle(id, type)
      setErrorBottomStyle(id, result[0])
      return result[0]
    }
    if (type === 'checkbox') {
      return setRequiredStyle(id, type)[0]
    }
  }
  function setFormDataField(key, value, id) {
    setFormData({
      ...formData,
      [key]: value,
    })
    const filterItem = formItemProps.find((item) => item.id === id)
    const type = filterItem ? filterItem.type : ''
    validateRequired(id, type, value)
    id === '#email' && validateEmail(false)
    id === '#phone' && validatePhone()
    id === '#account' && validateAccount()
    if (!id || id === '#info-from' || id === '#category') validateRadio(id, value)
  }
  function handleIKnow() {
    setIsDialogVisible(false)
    if (submitSuccess) {
      window.location.href = '/rc2025.html'
    }
  }
  function validateForm() {
    const result = formItemProps.map(({ id, type }) => {
      if (id === '#info-from') {
        return isOther ? validateOtherInput(otherDetail, id) : validateRadio(id)
      }
      if (id === '#category') {
        return validateRadio(id)
      }
      return validateRequired(id, type)
    })
    const uploadResult = uploadStudentCardResult && uploadStudentCardResult.success
    !uploadResult && setStudentCardFormError(true)
    return [...result, formData.category === '学生组' ? uploadResult : true].every((r) => r)
  }
  function validateRadio(id, value) {
    const radioMap = new Map([
      ['#category', 'category'],
      ['#info-from', 'infoFrom'],
    ])
    const val = value || formData[radioMap.get(id)]
    const checkedOther = val === '其他'
    if (id === '#info-from') {
      setIsOther(checkedOther)
      setErrorBottomStyle(id, checkedOther ? Boolean(otherDetail) : Boolean(val))
    } else {
      setErrorBottomStyle(id, Boolean(val))
    }
    return Boolean(val)
  }
  function validateOtherInput(val, id) {
    if (id === '#info-from') {
      setOtherDetail(val)
    }
    setErrorBottomStyle(id, Boolean(val))
    return Boolean(val)
  }

  function validateInputReg(id, reg) {
    const result = setRequiredStyle(id, 'input')
    const inputElement = document.querySelector(id)
    const isValid = reg.test(inputElement.value) && !!inputElement.value
    inputElement.className = !isValid ? result[2] : result[1]
    setErrorBottomStyle(id, isValid)
    return !inputElement.value || reg.test(inputElement.value)
  }
  function validateEmail(isError = false) {
    const result = setRequiredStyle('#email', 'input')
    const inputElement = document.querySelector('#email')
    inputElement.className = isError ? result[2] : result[1]
    setEmailError(isError)
    if (isError) return
    const emailReg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/g
    const isRight = validateInputReg('#email', emailReg)
    setEmailRight(!inputElement.value || isRight)
  }
  function validatePhone() {
    const phoneReg = /^1[3456789]\d{9}$/
    const isRight = validateInputReg('#phone', phoneReg)
    setPhoneRight(isRight)
  }
  function validateAccount() {
    const accountReg = /^(?=.*[a-zA-Z])[a-zA-Z0-9_]{6,20}$/
    const isRight = validateInputReg('#account', accountReg)
    setAccountRight(isRight)
  }

  function handleSubmit() {
    if (submitting) return
    if (!validateForm()) return
    Object.keys(formData).forEach(function (key) {
      if (formData[key] instanceof Array === false) {
        formData[key] = formData[key].trim()
      }
    })
    setSubmitting(true)
    axios({
      method: 'post',
      url: 'https://www.renderbus.com/api/rc/04/registration/form/submit',
      data: {
        ...formData,
        infoFrom: isOther ? otherDetail : formData.infoFrom,
        studentCard:
          uploadStudentCardResult && uploadStudentCardResult.success
            ? `https://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/04/student-card/${studentCardName}`
            : undefined,
      },
    })
      .then((response) => {
        const { data } = response
        setSubmitSuccess(data.code === 200)
        setIsDialogVisible(true)
      })
      .catch((err) => {
        if (err.response.status === 409) {
          validateEmail(true)
        }
        setIsDialogVisible(false)
        setSubmitSuccess(err.response.status !== 409)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  return (
    <React.Fragment>
      <Helmet>
        <meta http-equiv='Content-Security-Policy' content='upgrade-insecure-requests' />
        <script>
          {`
            var _hmt = _hmt || [];
            (function() {
              var hm = document.createElement("script");
              hm.src = "https://hm.baidu.com/hm.js?695fad530c8ccd7ba407ec0f01da7817";
              var s = document.getElementsByTagName("script")[0];
              s.parentNode.insertBefore(hm, s);
            })();
          `}
        </script>
      </Helmet>
      <SEO
        title='渲染大赛参赛报名表-瑞云3D渲染动画创作大赛'
        keywords='参赛报名表, 渲染大赛'
        description='3D渲染动画大赛，汇聚CG领域优秀艺术家，一起角逐大奖，赢专业评委点评，媒体曝光，为自己代言！一年一度渲染大赛现已开启正式报名，欢迎投稿参赛~'
        shareImgSrc='//rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/assets/banner.png'
        sharePostSlug='rc-form.html'
      />
      <PageContainer ref={pageContainer}>
        <RCFormSection>
          <RCSignFormContainer>
            <RCSignFormTitle>参赛报名表</RCSignFormTitle>
            <RCSignForm>
              <RCFormItem width='100%'>
                <RCLabel>参赛组别:</RCLabel>
                <div className='category-form-item'>
                  <RCRadioContainer width='50%' justifyContent='unset' gap='40px' gapMb='0px'>
                    {categoryOptions.map((source) => (
                      <label key={source}>
                        <RCRadio
                          type='radio'
                          value={source}
                          checked={source === formData.category}
                          onChange={(e) => setFormDataField('category', source, '#category')}
                        />
                        <span>{source}</span>
                      </label>
                    ))}
                  </RCRadioContainer>
                  <ErrorText id='category-error'>请选择参赛组别</ErrorText>
                </div>
              </RCFormItem>
              <RCFormItem>
                <RCLabel>您的姓名:</RCLabel>
                <RCInputField>
                  <RCInput
                    maxLength={8}
                    value={formData.name}
                    id='name'
                    onChange={(e) => setFormDataField('name', e.target.value, '#name')}
                  />
                  <ErrorText id='name-error'>请输入{inputFields.get('name')}</ErrorText>
                </RCInputField>
              </RCFormItem>
              <RCFormItem style={{ marginBottom: emailError && '10px' }}>
                <RCLabel>您的邮箱:</RCLabel>
                <RCInputField>
                  <RCInput
                    value={formData.email}
                    placeholder='接收作品提交邮件'
                    id='email'
                    onChange={(e) => setFormDataField('email', e.target.value, '#email')}
                  />
                  {emailError && (
                    <ErrorWrapper>
                      <span>!</span>
                      每个邮箱只能报名一次
                    </ErrorWrapper>
                  )}
                  <ErrorText id='email-error'>
                    请输入{!emailRight && '正确的'}
                    {inputFields.get('email')}
                  </ErrorText>
                </RCInputField>
              </RCFormItem>
              <RCFormItem>
                <RCLabel>您的电话:</RCLabel>
                <RCInputField>
                  <RCInput
                    type='tel'
                    value={formData.phone}
                    id='phone'
                    onChange={(e) => setFormDataField('phone', e.target.value, '#phone')}
                  />
                  <ErrorText id='phone-error'>
                    请输入{!phoneRight && '正确的'}
                    {inputFields.get('phone')}
                  </ErrorText>
                </RCInputField>
              </RCFormItem>
              <RCFormItem>
                <RCLabel>您的微信号:</RCLabel>
                <RCInputField>
                  <RCInput
                    value={formData.wechat}
                    placeholder='非微信昵称'
                    id='wechat'
                    onChange={(e) => setFormDataField('wechat', e.target.value, '#wechat')}
                  />
                  <ErrorText id='wechat-error'>请输入{inputFields.get('wechat')}</ErrorText>
                </RCInputField>
              </RCFormItem>
              <RCFormItem>
                <div className='account-form-item'>
                  <RCLabel>您的瑞云账号:</RCLabel>
                  <RCInputField>
                    <RCInput
                      className='account-input'
                      value={formData.account}
                      placeholder='(非UUID)'
                      id='account'
                      onChange={(e) => setFormDataField('account', e.target.value, '#account')}
                    />
                    <ErrorText id='account-error'>
                      请输入{!accountRight && '正确的'}
                      {inputFields.get('account')}
                    </ErrorText>
                    <RCRenderbusSupport isMobile>
                      本次大赛由Renderbus瑞云渲染提供免费云渲染支持
                      <RCRegisterLabel isMobile>
                        我还没有账号，点击
                        <a href={`${baseLink()}/sso/register?utm_source=renderchallenge`}>此处</a>
                        立即注册
                      </RCRegisterLabel>
                    </RCRenderbusSupport>
                    <RCRenderbusSupport>
                      <RCRegisterLabel>
                        我还没有账号，点击
                        <a href={`${baseLink()}/sso/register?utm_source=renderchallenge`}>此处</a>
                        立即注册
                      </RCRegisterLabel>
                      （本次大赛由Renderbus瑞云渲染提供免费云渲染支持）
                    </RCRenderbusSupport>
                  </RCInputField>
                </div>
              </RCFormItem>
              <RCFormItem style={{ display: formData.category === '学生组' ? 'flex' : 'none' }}>
                <div className='student-card-form-item'>
                  <RCLabel>请上传学生证:</RCLabel>
                  <WorkUploadInput
                    id='upload-student-card-container'
                    uploadResult={uploadStudentCardResult}
                    uploading={uploadingStudentCard}
                    studentCardFormError={studentCardFormError}
                  >
                    {!uploadingStudentCard && !uploadStudentCardResult && (
                      <React.Fragment>
                        <div className='upload-icon' />
                        <div className='upload-text'>
                          <p>
                            <span className='upload-text-mb-hidden'>拖拽文件到这里或</span>
                            <span id='browse-student-card-button'>点此添加</span>
                          </p>
                          <span>大小不超过2M</span>
                        </div>
                      </React.Fragment>
                    )}
                    {(uploadingStudentCard ||
                      (uploadStudentCardResult && uploadStudentCardResult.fail)) && (
                      <React.Fragment>
                        <img
                          className='png-icon'
                          src={uploadingStudentCard ? PngIcon : PngWarningIcon}
                          alt=''
                        />
                        <p className='file-name'>{studentCardName}</p>
                        {uploadingStudentCard ? (
                          <>
                            <p className='file-size'>{studentCardSize}</p>
                            <div className='progress'></div>
                          </>
                        ) : (
                          <p className='fail-tip'>
                            {isStudentCardTypeError ? '上传失败,仅支持jpg/png格式' : '上传失败'}
                          </p>
                        )}
                        <img
                          src={uploadingStudentCard ? CloseIcon : CloseWarningIcon}
                          alt=''
                          className='close-icon'
                          onClick={() => {
                            uploadingStudentCard && uploaderStudentCard.stop()
                            setUploaderStudentCard(null)
                            setUploadingStudentCard(false)
                            setUploadStudentCardResult(null)
                          }}
                        />
                      </React.Fragment>
                    )}
                    {!uploadingStudentCard &&
                      uploadStudentCardResult &&
                      uploadStudentCardResult.success && (
                        <React.Fragment>
                          <img
                            src={`https://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/04/student-card/${studentCardName}`}
                            alt=''
                            className='success-img'
                          />
                          <img
                            src={CloseIcon}
                            alt=''
                            className='close-icon'
                            onClick={() => {
                              setUploaderStudentCard(null)
                              setUploadStudentCardResult(null)
                            }}
                          />
                        </React.Fragment>
                      )}
                  </WorkUploadInput>
                </div>
              </RCFormItem>
              <BottomLine />
              {formData.category === '专业组' ? (
                <>
                  <RCOrgFormItem isStudent={formData.category === '学生组'}>
                    <RCLabel>您所在公司名/组织机构:</RCLabel>
                    <RCInputField>
                      <RCInput
                        value={formData.company}
                        id='company'
                        onChange={(e) => setFormDataField('company', e.target.value, '#company')}
                      />
                      <ErrorText id='company-error'>请输入{inputFields.get('company')}</ErrorText>
                    </RCInputField>
                  </RCOrgFormItem>
                  <RCOrgFormItem isStudent={formData.category === '学生组'}>
                    <RCLabel>您所担任的职务:</RCLabel>
                    <RCInputField>
                      <RCInput
                        value={formData.position}
                        id='position'
                        onChange={(e) => setFormDataField('position', e.target.value, '#position')}
                      />
                      <ErrorText id='position-error'>请输入{inputFields.get('position')}</ErrorText>
                    </RCInputField>
                  </RCOrgFormItem>
                </>
              ) : (
                <>
                  <RCOrgFormItem isStudent={formData.category !== '专业组'}>
                    <RCLabel>您所在的学校名称:</RCLabel>
                    <RCInputField>
                      <RCInput
                        value={formData.school}
                        id='school'
                        onChange={(e) => setFormDataField('school', e.target.value, '#school')}
                      />
                      <ErrorText id='school-error'>请输入{inputFields.get('school')}</ErrorText>
                    </RCInputField>
                  </RCOrgFormItem>
                  <RCOrgFormItem isStudent={formData.category !== '专业组'}>
                    <RCLabel>您所学的专业名称:</RCLabel>
                    <RCInputField>
                      <RCInput
                        value={formData.major}
                        id='major'
                        onChange={(e) => setFormDataField('major', e.target.value, '#major')}
                      />
                      <ErrorText id='major-error'>请输入{inputFields.get('major')}</ErrorText>
                    </RCInputField>
                  </RCOrgFormItem>
                </>
              )}
              <BottomLine />
              <RCSelectFormItem>
                <RCLabel className='long-question'>您是从哪里得知本次大赛？（单选）</RCLabel>
                <RCRadioContainer>
                  {infoSourceOptions.map((source) => (
                    <label key={source}>
                      <RCRadio
                        type='radio'
                        value={source}
                        checked={source === formData.infoFrom}
                        onChange={(e) => setFormDataField('infoFrom', source, '#info-from')}
                      />
                      <span>{source}</span>
                    </label>
                  ))}
                  <label>
                    <RCRadio
                      type='radio'
                      value='其他'
                      checked={formData.infoFrom && !infoSourceOptions.includes(formData.infoFrom)}
                      onChange={(e) => setFormDataField('infoFrom', '其他', '#info-from')}
                    />
                    <span>其他:</span>
                    <RCInput
                      className='other-input'
                      id='info-from'
                      onChange={(e) => validateOtherInput(e.target.value, '#info-from')}
                    />
                  </label>
                </RCRadioContainer>
                <ErrorText id='info-from-error'>
                  {isOther ? '请补充其他途径' : '请选择途径'}
                </ErrorText>
              </RCSelectFormItem>
              <BottomLine />
              <RCSelectFormItem>
                <RCAgreements>
                  <div>
                    <RCCheckbox
                      id='checkbox-agree-1'
                      checked={checkAgree}
                      onClick={() => setCheckAgree(!checkAgree)}
                    />
                    <span>
                      本人已知悉且同意诚信参赛，不弄虚作假，不恶意侵占比赛资源，若有违反，主办方将追回侵占资源费用且追究法律责任。
                    </span>
                  </div>
                  <div>
                    <RCCheckbox
                      id='checkbox-agree-2'
                      checked={checkAgree2}
                      onClick={() => setCheckAgree2(!checkAgree2)}
                    />
                    <span>
                      本人已知悉所有参赛者向大赛提交的参赛作品需拥有独立完整的著作权，或已经取得著作权人的合法授权。主办方及合作方拥有对全部参赛作品进行展示、报道、宣传及用于市场活动的权利，主办方及合作方有权将用于市场活动的作品加入赛事水印，参赛者保留对其作品的著作权。获奖者将获邀参与由主办方及合作方举办的相关市场推广活动。作品一经提交，将视为参赛者同意并遵守比赛相关规定。
                    </span>
                  </div>
                </RCAgreements>
              </RCSelectFormItem>
            </RCSignForm>
            <RCButton type='button' id='rc-form' onClick={handleSubmit}>
              <span>{submitting ? '提交中' : '提交报名表'}</span>
            </RCButton>
          </RCSignFormContainer>
        </RCFormSection>
        <QrContainer>
          <p>
            赛事问题可联系
            <br />
            大赛小助手微信
          </p>
          <img src={QRImg} alt='' />
          <p>
            微信号
            <br />
            rayvision1
          </p>
        </QrContainer>
        {/* <PageFooter>
          <p>
            <img src={PoliceIcon} alt='' />© 2022
            <a href='https://www.rayvision.com/' rel='nofollow'>
              深圳市瑞云科技股份有限公司
            </a>
            <a href='https://beian.miit.gov.cn/#/Integrated/index' target='_blank' rel='nofollow'>
              粤ICP备12028569号
            </a>
          </p>
          <p>中华人民共和国增值电信业务经营许可证编号：合字 B1-20200125</p>
        </PageFooter> */}
        <PageFooterMobile>
          <img src={QRImgMb} alt='二维码' />
          <div>
            <div>扫码添加小助手微信,加入赛事交流群!</div>
            <div>你将在1-3个工作日收到【报名通知邮件】,可根据报名邮件提交作品。</div>
          </div>
        </PageFooterMobile>
        <RCDialogContainer isActive={isDialogVisible} isSuccess={submitSuccess}>
          <div className='content'>
            <img src={QRImg} alt='' />
            <div className='text'>
              <div>
                <p>{submitSuccess ? '恭喜你报名表提交成功!' : '糟糕!报名表提交失败了!'}</p>
                <span>
                  {submitSuccess
                    ? '您将在1~3个工作日内收到成功报名通知邮件，如未收到，请加大赛小助手微信反馈！'
                    : '可能是因为网络原因，请您检查网络或者加大赛小助手微信反馈！'}
                </span>
              </div>
              <button onClick={handleIKnow}>
                <span>我知道了</span>
              </button>
            </div>
          </div>
        </RCDialogContainer>
      </PageContainer>
      <RCStyle />
    </React.Fragment>
  )
}

export default RenderContestSignUp
